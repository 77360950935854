import { Buffer } from 'buffer';

export default defineNuxtRouteMiddleware((to, _from) => {
	// try {
	//     const sample = {
	//         MerchantCode: "M39017",
	//         PaymentId: "16",
	//         RefNo: "W039438",
	//         Amount: "16.00",
	//         Currency: "MYR",
	//         Remark: null,
	//         TransId: "T073921370724",
	//         AuthCode: null,
	//         Status: "0",
	//         ErrDesc: "Customer Cancel Transaction",
	//         ErrStatus: null,
	//         Signature: "d703ae194da73d7f48a0e9f2fa4a793518359b6c1fcdd4b09bbaac8215ab33d5",
	//         VTVLEncryptResultval: null,
	//         ActionType: null,
	//     };

	try {
		const sample = to.query.data;
		const id = to.params.id;

		const data = JSON.parse(Buffer.from(sample, 'base64').toString('utf-8'));

		if (to.path.includes('payment-status')) {
			if (data.Status === '1') {
				return navigateTo({ path: `/order/${id}/payment-success`, query: { transId: data.TransId, refNo: data.RefNo } }, { replace: true });
			} else if (data.Status === '6') {
				return navigateTo({ path: `/order/${id}/payment-pending`, query: { transId: data.TransId, refNo: data.RefNo } }, { replace: true });
			} else {
				return navigateTo({ path: `/order/${id}/payment-fail`, query: { message: data.ErrDesc } }, { replace: true });
			}
		}
	} catch (error) {
		// console.log("error exception : something went wrong, " + error);
		return navigateTo({ path: '/error', query: { message: error } }, { replace: true });
	}
});
